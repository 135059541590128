import React from "react"
import {Helmet} from "react-helmet";
import Layout from "../components/layout"
import { graphql } from "gatsby"

import Homepage from '../components/homepage'

export default ({ data }) => {
  return (
    <Layout>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>{data.site.siteMetadata.title}</title>
        <meta name="robots" content="index, follow" />
        <meta name="description" content={data.site.siteMetadata.desc} />
        <meta property="og:title" content={data.site.siteMetadata.title} />
        <meta property="og:description" content={data.site.siteMetadata.desc} />
        <meta property="og:url" content={data.site.siteMetadata.siteUrl} />
        <meta property="og:site_name" content={data.site.siteMetadata.title} />
        <meta property="og:image" content="/icons/icon-512x512.png" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={data.site.siteMetadata.title} />
        <meta name="twitter:image" content="/icons/icon-512x512.png" />
      </Helmet>
      <Homepage contact={data.pageContact} home={data.pageHome} />
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title,
        desc,
        siteUrl
      }
    },
    pageContact: allWordpressPage (filter: { slug: { eq: "contact" } } ) {
      edges {
        node {
          slug,
          title,
          content,
          acf {
            hours
            phone
            email
            address
          }
        }
      }
    },
    pageHome: allWordpressPage ( filter: { slug: { eq: "home" } }) {
      edges {
        node {
          slug,
          title,
          content,
          acf {
            promo_content
          }
        }
      }
    }
  }
`
